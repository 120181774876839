@charset "UTF-8";
:root {
  --brand-gold: #ffc87f;
  --brand-cream: #f0e7e1;
  --brand-grey: #484848;
  --brand-black: #050505;
  --brand-dark-grey: #181818;
  --brand-white: #fff;
  --theme-primary: var(--brand-gold);
  --theme-secondary: var(--brand-cream);
  --theme-tertiary: var(--brand-grey);
  --theme-color-font: var(--brand-cream);
  --theme-black: var(--brand-black);
  --theme-white: var(--brand-white);
  --theme-background: var(--theme-black);
  --input-max-width: 420px;
}
html {
  background-color: var(--theme-background);
  color: var(--theme-color-font);
  font-family: "Work Sans";
}
body {
  margin: 0;
  padding: 0;
}
* {
  margin: 0;
  color: white;
}
ul {
  list-style: none;
  padding: 0;
}
.container {
  max-width: 720px;
  margin: 0 auto 72px;
  display: flex;
  flex-direction: column;
}
footer {
  max-width: 720px;
  position: relative;
  margin: 0 auto 24px;
  display: flex;
  justify-content: space-between;
}
footer #scroll-to-top {
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: transparent;
  text-decoration: underline;
  font-size: 14px;
  color: white;
  border: none;
  font-weight: 700;
  text-transform: uppercase;
}
.bg-image {
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  filter: blur(20px);
  opacity: 0.5;
  z-index: 0;
  background-image: url("https://images.unsplash.com/photo-1628078535831-2582ee04c274?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyODMwNjU5NQ&ixlib=rb-1.2.1&q=80&w=1080");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
header {
  z-index: 1;
  position: relative;
}
.header-conatiner {
  text-align: center;
  text-transform: uppercase;
  font-family: "Work Sans";
  padding: 52px 16px 48px;
}
.header-conatiner h1 {
  font-weight: 900;
}
.header-conatiner p {
  margin: 0 0 8px;
}
.search-input--container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 0;
  padding: 8px 0;
  z-index: 10;
}
.search-input--container:before {
  content: attr(data-count);
  position: absolute;
  width: calc(100% - 64px);
  max-width: var(--input-max-width);
  margin: 0 auto 2px;
  text-align: right;
  font-size: 12px;
}
.search-input--container input {
  position: relative;
  background-color: transparent;
  border: 2px solid var(--brand-cream);
  width: 100%;
  max-width: var(--input-max-width);
  margin: 0 auto;
  padding: 8px 16px;
  outline: none;
  color: #f4f4f4;
  font-family: "Roboto Mono";
}
.search-input--container input:active, .search-input--container input:focus {
  border: 2px solid var(--theme-primary);
}
#results-container {
  padding: 0 4px;
}
.result-item {
  position: relative;
  margin: 0 0 12px;
  padding: 8px 12px;
  border-style: solid;
  border-width: 3px;
  border-color: transparent;
  color: white;
  border: 1px solid #303030;
  transition: all 0.3s ease;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
}
.result-item:before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(0, 0, 0, 0.5);
  filter: blur(10px);
  margin: -20px;
}
.result-item:hover {
  border: 1px solid rgba(255, 200, 128, 0.5);
}
.result-item:hover:before {
  box-shadow: inset 0 0 2000px rgba(50, 50, 50, 0.5);
}
.result-item:hover .title {
  text-decoration: underline;
}
.result-item .title {
  margin: 0;
  font-size: 24px;
  display: flex;
  text-transform: uppercase;
  font-weight: 700;
}
.result-item .title a {
  color: white;
  text-decoration: none;
}
.result-item .title span {
  height: 20px;
  width: 20px;
  display: flex;
  margin-left: 6px;
  position: relative;
}
.result-item .title span img {
  position: absolute;
  height: 100%;
  width: 100%;
  transition: all 0.2s ease;
  border-radius: 2px;
}
.result-item .title span:hover img {
  position: absolute;
  height: 400%;
  width: 400%;
  z-index: 10;
  border-radius: 4px;
}
.description {
  margin: 2px ​0 0;
  font-size: 14px;
}
.pill--container {
  display: inline-block;
  margin: 8px 0 0;
}
.pill {
  background-color: var(--brand-dark-grey);
  padding: 4px 6px;
  text-transform: uppercase;
  font-size: 12px;
  float: left;
  margin-right: 4px;
}
.socials--container {
  display: flex;
  position: absolute;
  top: 4px;
  right: 8px;
}
.icon {
  height: 18px;
  width: 18px;
  padding: 2px;
  margin-left: 4px;
  display: flex;
}
.icon a {
  background-color: #ffffff;
  height: 18px;
  width: 18px;
  transition: background 0.3s ease;
  border-style: solid;
  border-width: 0px;
  margin: 0;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: 16px;
  -webkit-mask-size: 16px;
  mask-position: center center;
  -webkit-mask-position: center center;
}
.icon a:focus, .icon a:active, .icon a:hover {
  background-color: #404855;
}
[data-icon=github] {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='23.413' viewBox='0 0 24 23.413' class='fill-current'%3E%3Cpath id='Path_21' data-name='Path 21' d='M22.39,11.342a11.948,11.948,0,0,0-4.367-4.367A11.737,11.737,0,0,0,12,5.365,11.738,11.738,0,0,0,5.977,6.974a11.946,11.946,0,0,0-4.367,4.367A11.738,11.738,0,0,0,0,17.365a11.662,11.662,0,0,0,2.289,7.055A11.753,11.753,0,0,0,8.2,28.755a.7.7,0,0,0,.625-.109.611.611,0,0,0,.2-.469q0-.047-.008-.844t-.008-1.39l-.359.062a4.585,4.585,0,0,1-.867.055A6.607,6.607,0,0,1,6.7,25.951a2.427,2.427,0,0,1-1.047-.468,1.983,1.983,0,0,1-.687-.961l-.156-.36a3.9,3.9,0,0,0-.492-.8,1.885,1.885,0,0,0-.68-.594l-.109-.078a1.147,1.147,0,0,1-.2-.188.857.857,0,0,1-.141-.219q-.047-.11.078-.18a1.013,1.013,0,0,1,.453-.07l.312.047a2.281,2.281,0,0,1,.773.375,2.522,2.522,0,0,1,.758.812,2.754,2.754,0,0,0,.867.977,1.84,1.84,0,0,0,1.023.336,4.443,4.443,0,0,0,.891-.078,3.108,3.108,0,0,0,.7-.235,2.532,2.532,0,0,1,.766-1.609,10.7,10.7,0,0,1-1.6-.281,6.377,6.377,0,0,1-1.469-.61,4.206,4.206,0,0,1-1.258-1.047,5.031,5.031,0,0,1-.82-1.641,7.8,7.8,0,0,1-.32-2.344A4.557,4.557,0,0,1,5.578,13.52a4.2,4.2,0,0,1,.109-3.187,2.189,2.189,0,0,1,1.344.211,9.394,9.394,0,0,1,1.3.6q.414.25.664.422a11.284,11.284,0,0,1,6,0l.594-.375a8.409,8.409,0,0,1,1.437-.688,2.048,2.048,0,0,1,1.266-.172,4.16,4.16,0,0,1,.125,3.187,4.558,4.558,0,0,1,1.235,3.219,7.889,7.889,0,0,1-.32,2.351,4.835,4.835,0,0,1-.828,1.641,4.368,4.368,0,0,1-1.266,1.039,6.389,6.389,0,0,1-1.469.609,10.688,10.688,0,0,1-1.6.282,2.783,2.783,0,0,1,.812,2.219v3.3a.622.622,0,0,0,.2.469.679.679,0,0,0,.617.109,11.751,11.751,0,0,0,5.914-4.336A11.664,11.664,0,0,0,24,17.364,11.748,11.748,0,0,0,22.39,11.342Z' transform='translate(0 -5.365)'%3E%3C/path%3E%3C/svg%3E");
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='23.413' viewBox='0 0 24 23.413' class='fill-current'%3E%3Cpath id='Path_21' data-name='Path 21' d='M22.39,11.342a11.948,11.948,0,0,0-4.367-4.367A11.737,11.737,0,0,0,12,5.365,11.738,11.738,0,0,0,5.977,6.974a11.946,11.946,0,0,0-4.367,4.367A11.738,11.738,0,0,0,0,17.365a11.662,11.662,0,0,0,2.289,7.055A11.753,11.753,0,0,0,8.2,28.755a.7.7,0,0,0,.625-.109.611.611,0,0,0,.2-.469q0-.047-.008-.844t-.008-1.39l-.359.062a4.585,4.585,0,0,1-.867.055A6.607,6.607,0,0,1,6.7,25.951a2.427,2.427,0,0,1-1.047-.468,1.983,1.983,0,0,1-.687-.961l-.156-.36a3.9,3.9,0,0,0-.492-.8,1.885,1.885,0,0,0-.68-.594l-.109-.078a1.147,1.147,0,0,1-.2-.188.857.857,0,0,1-.141-.219q-.047-.11.078-.18a1.013,1.013,0,0,1,.453-.07l.312.047a2.281,2.281,0,0,1,.773.375,2.522,2.522,0,0,1,.758.812,2.754,2.754,0,0,0,.867.977,1.84,1.84,0,0,0,1.023.336,4.443,4.443,0,0,0,.891-.078,3.108,3.108,0,0,0,.7-.235,2.532,2.532,0,0,1,.766-1.609,10.7,10.7,0,0,1-1.6-.281,6.377,6.377,0,0,1-1.469-.61,4.206,4.206,0,0,1-1.258-1.047,5.031,5.031,0,0,1-.82-1.641,7.8,7.8,0,0,1-.32-2.344A4.557,4.557,0,0,1,5.578,13.52a4.2,4.2,0,0,1,.109-3.187,2.189,2.189,0,0,1,1.344.211,9.394,9.394,0,0,1,1.3.6q.414.25.664.422a11.284,11.284,0,0,1,6,0l.594-.375a8.409,8.409,0,0,1,1.437-.688,2.048,2.048,0,0,1,1.266-.172,4.16,4.16,0,0,1,.125,3.187,4.558,4.558,0,0,1,1.235,3.219,7.889,7.889,0,0,1-.32,2.351,4.835,4.835,0,0,1-.828,1.641,4.368,4.368,0,0,1-1.266,1.039,6.389,6.389,0,0,1-1.469.609,10.688,10.688,0,0,1-1.6.282,2.783,2.783,0,0,1,.812,2.219v3.3a.622.622,0,0,0,.2.469.679.679,0,0,0,.617.109,11.751,11.751,0,0,0,5.914-4.336A11.664,11.664,0,0,0,24,17.364,11.748,11.748,0,0,0,22.39,11.342Z' transform='translate(0 -5.365)'%3E%3C/path%3E%3C/svg%3E");
}
[data-icon=linkedin] {
  mask-image: url("data:image/svg+xml,%3Csvg id='linkedin' xmlns='http://www.w3.org/2000/svg' width='23.98' height='23.98' viewBox='0 0 23.98 23.98' class='fill-current'%3E%3Cpath id='Path_22' d='M23.981,23.987h.006V15.191c0-4.3-.926-7.617-5.956-7.617a5.222,5.222,0,0,0-4.7,2.585h-.07V7.976H8.489v16.01h4.966V16.058c0-2.087.4-4.106,2.981-4.106,2.547,0,2.585,2.382,2.585,4.24v7.795Z' transform='translate(-0.007 -0.006)'%3E%3C/path%3E%3Cpath id='Path_23' d='M.4,7.977H5.368v16.01H.4Z' transform='translate(0 -0.006)'%3E%3C/path%3E%3Cpath id='Path_24' d='M2.88,0a2.893,2.893,0,1,0,2.88,2.88A2.881,2.881,0,0,0,2.88,0Z'%3E%3C/path%3E%3C/svg%3E");
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg id='linkedin' xmlns='http://www.w3.org/2000/svg' width='23.98' height='23.98' viewBox='0 0 23.98 23.98' class='fill-current'%3E%3Cpath id='Path_22' d='M23.981,23.987h.006V15.191c0-4.3-.926-7.617-5.956-7.617a5.222,5.222,0,0,0-4.7,2.585h-.07V7.976H8.489v16.01h4.966V16.058c0-2.087.4-4.106,2.981-4.106,2.547,0,2.585,2.382,2.585,4.24v7.795Z' transform='translate(-0.007 -0.006)'%3E%3C/path%3E%3Cpath id='Path_23' d='M.4,7.977H5.368v16.01H.4Z' transform='translate(0 -0.006)'%3E%3C/path%3E%3Cpath id='Path_24' d='M2.88,0a2.893,2.893,0,1,0,2.88,2.88A2.881,2.881,0,0,0,2.88,0Z'%3E%3C/path%3E%3C/svg%3E");
}
[data-icon=website] {
  mask-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 32 32' style='enable-background:new 0 0 32 32;' xml:space='preserve'%3E%3Cpath d='M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0z M16.5,2.7c1.3,0.1,2.5,0.2,3.7,0.6l-0.6,0.9l0.5,1l-0.7,1.3 L22.2,7l1,4.8l2.3,3.1l2,1.3L26.8,14l0.8-0.9l1.5,0.4c0.2,0.8,0.2,1.7,0.2,2.5c0,0.2,0,0.4,0,0.6l-0.1-0.2l-1.3,0.5l-0.3,0.8 L26.8,18l0.2,2.2l0.5,0.8l-0.4,1l-2,3.2l-0.2,0.6c-2.4,2.2-5.6,3.6-9.1,3.6c-4.1,0-7.7-1.8-10.2-4.7L4,20L5,18.2l-1.6-0.9l1.8-0.9 l0.1-1L4.8,14l1,0.1l1,1.6l1,0.5L7.5,15l1-1l1.6,1.2l0.9-0.7l-0.4-1.8l1.3-1.8L11.8,9l1.6-1l-1.1-2.7l3-0.2L17,3.1L16.5,2.7z M14.7,15.5l-3.5,2.7l0.2,3.3l0.9,0.8l2-1.1l1,0.3l1.8,1.9l1.1-0.3l1.1-2.7l-0.7-1.8l-1.4-2.8l-1,1.7L14.7,15.5z M21.3,21.1 l-0.2,0.1L21,22.9l-0.6,0.8l0.7,0.6l0.8-1L21.3,21.1z M19.9,24.3l-1.7,2l0.5,0.5l2-1.9L19.9,24.3z'/%3E%3C/svg%3E%0A");
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 32 32' style='enable-background:new 0 0 32 32;' xml:space='preserve'%3E%3Cpath d='M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0z M16.5,2.7c1.3,0.1,2.5,0.2,3.7,0.6l-0.6,0.9l0.5,1l-0.7,1.3 L22.2,7l1,4.8l2.3,3.1l2,1.3L26.8,14l0.8-0.9l1.5,0.4c0.2,0.8,0.2,1.7,0.2,2.5c0,0.2,0,0.4,0,0.6l-0.1-0.2l-1.3,0.5l-0.3,0.8 L26.8,18l0.2,2.2l0.5,0.8l-0.4,1l-2,3.2l-0.2,0.6c-2.4,2.2-5.6,3.6-9.1,3.6c-4.1,0-7.7-1.8-10.2-4.7L4,20L5,18.2l-1.6-0.9l1.8-0.9 l0.1-1L4.8,14l1,0.1l1,1.6l1,0.5L7.5,15l1-1l1.6,1.2l0.9-0.7l-0.4-1.8l1.3-1.8L11.8,9l1.6-1l-1.1-2.7l3-0.2L17,3.1L16.5,2.7z M14.7,15.5l-3.5,2.7l0.2,3.3l0.9,0.8l2-1.1l1,0.3l1.8,1.9l1.1-0.3l1.1-2.7l-0.7-1.8l-1.4-2.8l-1,1.7L14.7,15.5z M21.3,21.1 l-0.2,0.1L21,22.9l-0.6,0.8l0.7,0.6l0.8-1L21.3,21.1z M19.9,24.3l-1.7,2l0.5,0.5l2-1.9L19.9,24.3z'/%3E%3C/svg%3E%0A");
}
[data-icon=demo] {
  mask-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='vial' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 480 512' class='svg-inline--fa fa-vial fa-w-15 fa-3x'%3E%3Cpath fill='currentColor' d='M477.7 186.1L309.5 18.3c-3.1-3.1-8.2-3.1-11.3 0l-34 33.9c-3.1 3.1-3.1 8.2 0 11.3l11.2 11.1L33 316.5c-38.8 38.7-45.1 102-9.4 143.5 20.6 24 49.5 36 78.4 35.9 26.4 0 52.8-10 72.9-30.1l246.3-245.7 11.2 11.1c3.1 3.1 8.2 3.1 11.3 0l34-33.9c3.1-3 3.1-8.1 0-11.2zM318 256H161l148-147.7 78.5 78.3L318 256z' class=''%3E%3C/path%3E%3C/svg%3E");
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='vial' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 480 512' class='svg-inline--fa fa-vial fa-w-15 fa-3x'%3E%3Cpath fill='currentColor' d='M477.7 186.1L309.5 18.3c-3.1-3.1-8.2-3.1-11.3 0l-34 33.9c-3.1 3.1-3.1 8.2 0 11.3l11.2 11.1L33 316.5c-38.8 38.7-45.1 102-9.4 143.5 20.6 24 49.5 36 78.4 35.9 26.4 0 52.8-10 72.9-30.1l246.3-245.7 11.2 11.1c3.1 3.1 8.2 3.1 11.3 0l34-33.9c3.1-3 3.1-8.1 0-11.2zM318 256H161l148-147.7 78.5 78.3L318 256z' class=''%3E%3C/path%3E%3C/svg%3E");
}
/* work-sans-vietnamese-400-normal*/
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('work-sans-vietnamese-400-normal.ce20f745.woff2') format('woff2'), url('work-sans-all-400-normal.1813c163.woff') format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* work-sans-latin-ext-400-normal*/
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('work-sans-latin-ext-400-normal.b1ebc631.woff2') format('woff2'), url('work-sans-all-400-normal.1813c163.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* work-sans-latin-400-normal*/
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('work-sans-latin-400-normal.bbea649f.woff2') format('woff2'), url('work-sans-all-400-normal.1813c163.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*# sourceMappingURL=index.ba907f4d.css.map */
